import React, { Component } from "react";

class Counter extends Component {
  state = {
    value: this.props.value,
    imageURL: "https://picsum.photos/200",
    tags: ["tag1", "tag2", "tag3"],
    id: this.props.id,
  };

  styles = {
    fontSize: 10,
    fontWeight: "Bolder",
  };
  media = {
    imagelink: "https://picsum.photos/200",
  };

  renderTags() {
    if (this.state.tags.length === 0) return <p>there is no array</p>;
    return (
      <ul>
        {this.state.tags.map((tag) => (
          <li key={tag}>{tag}</li>
        ))}
      </ul>
    );
  }
  render() {
    console.log("Props of this componenet", this.props);
    return (
      <React.Fragment>
        <div className="m-2">
          <span style={this.styles} className={this.getBadgeClasses()}>
            {this.formatCount()}
          </span>
          <button
            className="btn btn-secondary btn-sm m-2"
            onClick={() => this.updateValue({ product: this.props.id })}
          >
            Increment
          </button>

          <button
            className="btn btn-danger btn-sm m-2"
            onClick={() => this.props.onDelete(this.props.id)}
          >
            Delete
          </button>
        </div>
      </React.Fragment>
    );
  }

  //constructor() {
  //    super();
  //   this.updateValue = this.updateValue.bind(this);
  //  }
  updateValue = (product) => {
    console.log(product);
    this.setState({ value: this.state.value + 1 });
  };
  getBadgeClasses() {
    let classes = "badge m-2 badge-";
    classes += this.state.value === 0 ? "warning" : "primary";
    return classes;
  }

  formatCount() {
    const { value } = this.state;
    return value === 0 ? "Zero" : value;
  }
}

export default Counter;
