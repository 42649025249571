import React, { Component } from "react";
import Counter from "./counter";

class Counters extends Component {
  state = {
    counters: [
      { id: 1, value: 0 },
      { id: 2, value: 1 },
      { id: 3, value: 3 },
      { id: 4, value: 4 },
    ],
  };

  handleDelete = (counterID) => {
    console.log("Delete Handler Called", counterID);
    const counters = this.state.counters.filter((c) => c.id != counterID);
    this.setState({ counters });
  };
  render() {
    return (
      <div>
        <button className="btn btn-primary m-5">Reset</button>
        {this.state.counters.map((counter) => (
          <Counter
            key={counter.id}
            value={counter.value}
            id={counter.id}
            selected="true"
            onDelete={this.handleDelete}
          />
        ))}
      </div>
    );
  }
}

export default Counters;
